export default {
  info: {
    disabled: false,
    title: "Weather",
    img: require(`../../../assets/img/icons/weather.svg`)
  },
  structure: {
    key: "Weather",
    location: undefined
  },
  settings: [
    {
      label: "Location",
      inputType: "text",
      get: ob => ob.structure.location,
      set: (ob, value) => (ob.structure.location = value)
    }
  ]
};
