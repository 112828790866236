<!-- 
 Extends @/components/design-elements-section
-->

<script>
import DesignElementsSection from "commonsfrontend/src/maker/vue-files/components/design-elements-section";
import services from "@/types/design-elements/services/index.ts";
/* eslint-disable no-undef */
export default {
  extends: DesignElementsSection,
  data: function() {
    return {
      domClass: {
        height: "basis-1",
        overflow: "of-x"
      }
    };
  },
  computed: {
    elements: () => services
  },
  methods: {
    dragstartHandler(e, item) {
      const anim = gsap.timeline();
      const viewPlugins = document.querySelector(".view-plugins");
      anim.fromTo(
        viewPlugins,
        {
          "background-color": viewPlugins.style["background-color"]
        },
        {
          "background-color": "#fff691",
          duration: 0.5,
          yoyo: true,
          repeat: -1
        }
      );

      this.$once("dragEndHandler", () => {
        anim
          .pause(anim.endTime())
          .clear()
          .kill();
      });

      e.dataTransfer.dropEffect = "copy";
      e.dataTransfer.effectAllowed = "all";
      e.dataTransfer.setData("IsService", true);
      e.dataTransfer.setData("service", JSON.stringify(item));
    }
  }
};
</script>
