<template>
  <component :is="mode" ref="ActiveDesignContainer" />
</template>

<script>
import DesignSettings from "./DesignSettings";
import PluginServiceSettings from "./PluginServiceSettings";
export default {
  components: { DesignSettings, PluginServiceSettings },
  data() {
    return {
      mode: "design-settings"
    };
  },
  created() {
    this.$root.$on("workspace/settings::targetId", targetId => {
      if (this.mode === "design-settings") return;
      this.mode = "design-settings";
    });

    this.$root.$on("workspace/settings::plugin", plugin => {
      if (
        this.mode === "plugin-service-settings" &&
        this.$refs.ActiveDesignContainer.selectedObject &&
        this.$refs.ActiveDesignContainer.selectedObject.structure?.key ===
          plugin.structure?.key
      )
        return;
      this.mode = "plugin-service-settings";
      this.$nextTick(() => {
        this.$refs.ActiveDesignContainer.selectedObject = plugin;
      });
    });

    this.$root.$on("workspace/settings::service", service => {
      if (
        this.mode === "plugin-service-settings" &&
        this.$refs.ActiveDesignContainer.selectedObject &&
        this.$refs.ActiveDesignContainer.selectedObject.structure?.key ===
          service.structure?.key
      )
        return;
      this.mode = "plugin-service-settings";
      this.$nextTick(() => {
        this.$refs.ActiveDesignContainer.selectedObject = service;
      });
    });

    /** New Setting     
      this.$root.$on("workspace/settings::plugin-sound", () => {
        if (this.mode === "sound-settings") return;
        this.mode = "sound-settings";
      });
    */
  }
};
</script>

<style></style>
