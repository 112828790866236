<script>
import RunMode from "commonsfrontend/src/maker/vue-files/workspace/sections/screen/modes/run.vue";
import { mapGetters } from "vuex";

export default {
  extends: RunMode,
  data() {
    return {
      iframePath: "app-preview"
    };
  },
  computed: {
    ...mapGetters("workspace/session", ["appJson"])
  }
};
</script>
