<template>
  <div class="view-phone">
    <div :class="screenClass">
      <edit-mode v-if="isEditingMode" :page="showingDesignPage"></edit-mode>
      <run-mode v-if="isRunningMode"> </run-mode>
    </div>
  </div>
</template>

<script>
import editMode from "./modes/edit";
import runMode from "./modes/run";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { editMode, runMode },
  computed: {
    // vuex-related
    ...mapGetters("workspace/session", [
      "isRunningMode",
      "isEditingMode",
      "showingScreenPage",
      "screenOs",
      "isScreenOsIos",
      "isScreenOsAndroid",
      "showingDesignPage"
    ]),
    // dom-manipulation
    screenClass() {
      return {
        "view-phone-container": true,
        ios: this.isScreenOsIos,
        android: this.isScreenOsAndroid
      };
    }
  }
};
</script>

<style lang="scss" scoped>
$ratio: 0.56;
$height: 65vh;
$width: calc(#{$height} * #{$ratio});
$iosColor: rgba(224, 224, 224, 0.863);
$androidColor: rgb(56, 57, 58);
.view-phone {
  //background: url("./assets/img/pixel-4.png") no-repeat;
  //padding: 16px 0;
  background-size: 100%;
  max-width: 330px;
  margin: 0 auto;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-position: top;

  .vertical-mode & {
    max-width: 90%;
  }

  .view-phone-container {
    display: block;
    width: $width;
    height: $height;
    position: relative;
    border: 10px solid $iosColor;
    border-radius: 30px;

    .vertical-mode & {
      width: 100%;
      height: 240px;

      &:before {
        content: "";
        position: absolute;
        top: -15px;
        left: 68px;
        right: initial;
        width: 54px;
        height: 5px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        background: black;
      }
    }

    .ion-page {
      border-radius: 22px !important;
    }

    &:before {
      content: "";
      position: absolute;
      top: 68px;
      right: -15px;
      width: 5px;
      height: 54px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      background: $iosColor;
    }
  }

  .ios {
    border-color: $iosColor;

    &:before {
      background: $iosColor;
    }
  }

  .android {
    border-color: $androidColor !important;

    &:before {
      background: $androidColor !important;
    }
  }
}
</style>
