<!-- 
 Extends @/components/design-elements-section
-->

<script>
import DesignElementsSection from "commonsfrontend/src/maker/vue-files/components/design-elements-section";
import elements from "@/types/design-elements/components/index.ts";
/* eslint-disable no-undef */
export default {
  extends: DesignElementsSection,
  computed: {
    /* Filter elements which are not hidden */
    elements: () =>
      Object.keys(elements).reduce((acc, name) => {
        const designObject = elements[name];
        if (!designObject.info.hidden) acc[name] = designObject;
        return acc;
      }, {})
  },
  methods: {
    async dragstartHandler(e, item) {
      const anim = gsap.timeline();
      if (item.structure.tag === "ion-toolbar") {
        anim.fromTo(
          "ion-header",
          { background: "transparent" },
          {
            background: "#fff691",
            yoyo: 1,
            duration: 0.5,
            repeat: -1
          }
        );
      } else {
        anim.fromTo(
          "ion-content",
          {
            "--background": "white"
          },
          {
            "--background": "#fff691",
            duration: 0.5,
            yoyo: true,
            repeat: -1
          }
        );
      }

      this.$once("dragEndHandler", () => {
        anim
          .pause(anim.endTime())
          .clear()
          .kill();
      });

      console.log(item);
      e.dataTransfer.dropEffect = "copy";
      e.dataTransfer.effectAllowed = "all";
      e.dataTransfer.setData("item", JSON.stringify(item.structure));
      e.dataTransfer.setData("newItem", true);
      if (item.type && item.type == "plugin")
        e.dataTransfer.setData("plugin", true);
    }
  }
};
</script>
