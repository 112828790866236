<script>
import SettingsContainer from "commonsfrontend/src/maker/vue-files/workspace/sections/settings/SettingsContainer.vue";
import findVNodeById from "../../../../types/shared/findVNodeById.ts";
import { mapActions } from "vuex";
import elements from "@/types/design-elements/components/index.ts";
export default {
  extends: SettingsContainer,
  data() {
    return {
      selectedObject: null,
      htmlStyles: {
        thumbnail: "max-width:25px; filter: invert(1);"
      },
      renderOptions: {
        move: true
      }
    };
  },
  computed: {
    // design-elements-related
    info() {
      if (
        elements[this.selectedObject?.data?.props?.configurable] &&
        elements[this.selectedObject.data.props.configurable].info
      ) {
        return elements[this.selectedObject.data.props.configurable].info;
      } else return {};
    },
    // design-elements-related
    settings() {
      if (
        elements[this.selectedObject?.data?.props?.configurable] &&
        elements[this.selectedObject.data.props.configurable].settings
      ) {
        return elements[this.selectedObject.data.props.configurable].settings;
      } else return [];
    },
    thumbnail() {
      return this.info?.img;
    },
    objectId() {
      return this.selectedObject?.data?.attrs?.id;
    },
    showDeleteButton() {
      return this.selectedObject && this.selectedObject.tag !== "ion-app";
    }
  },
  watch: {
    // design-elements-related
    targetId: {
      immediate: true,
      handler(id) {
        const root = this.showingDesignPage;
        if (!id || !root) {
          this.selectedObject = null;
        } else {
          this.selectedObject = findVNodeById(root, id);
        }
      }
    }
  },
  methods: {
    // vuex-related
    ...mapActions("workspace/session", ["moveDesignElement"])
  }
};
</script>
