export default {
  info: {
    disabled: false,
    title: "Camera",
    img: require(`../../../assets/img/icons/camera.svg`)
  },
  structure: {
    key: "Camera",
    mute: true
  },
  settings: [
    {
      label: "Mute voice",
      inputType: "select",
      options: [
        {
          text: "Mute",
          value: true
        },
        {
          text: "Unmute",
          value: false
        }
      ],
      get: ob => ob.structure.mute,
      set: (ob, value) => (ob.structure.mute = value)
    }
  ]
};
