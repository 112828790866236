<template>
  <div
    class="view-plugins"
    @drop="dropHandler"
    @dragover.prevent
    @dragenter.prevent
  >
    <div class="title" title="Inserted Plugins & Services">
      <img :src="require('../../../../assets/img/icons/electric-plugin.svg')" />
    </div>
    <ul>
      <li
        v-for="(plugin, pI) in pluginList"
        :key="'p' + pI"
        @click="openPluginSettings(plugin)"
      >
        <img
          :src="plugin.info.img"
          :alt="plugin.info.title + ' Plugin'"
          :title="plugin.info.title + ' Plugin'"
        />
      </li>
      <li
        v-for="(service, sI) in serviceList"
        :key="'s' + sI"
        @click="openServiceSettings(service)"
      >
        <img
          :src="service.info.img"
          :alt="service.info.title + ' Service'"
          :title="service.info.title + ' Service'"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters("workspace/session", ["serviceList", "pluginList"])
  },
  methods: {
    ...mapActions("workspace/session", ["registerService", "registerPlugin"]),
    dropHandler(e) {
      e.preventDefault();
      e.stopPropagation();
      if (e.dataTransfer.getData("IsService")) {
        const service = JSON.parse(e.dataTransfer.getData("service"));
        this.registerService(service);
      } else if (e.dataTransfer.getData("IsPlugin")) {
        const service = JSON.parse(e.dataTransfer.getData("plugin"));
        this.registerPlugin(service);
      }
      e.target.classList.remove("dragenter");
    },
    openPluginSettings(plugin) {
      this.$root.$emit("workspace/settings::plugin", plugin);
    },
    openServiceSettings(service) {
      this.$root.$emit("workspace/settings::service", service);
    }
  }
};
</script>

<style lang="scss" scoped>
.view-plugins {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: #e2e2e2;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding: 0.75rem;

  .title {
    display: inline-block;
    color: #8d8d8d;
    // border: 1px solid #8d8d8d;
    margin: -5px;
    padding: 5px;
    margin-right: 1rem;
    font-weight: 500;
    img {
      width: 20px;
      display: block;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    display: flex;
    justify-content: center;
    align-items: center;

    li {
      margin-right: 1em;
      display: inline-block;
      cursor: pointer;

      img {
        width: 20px;
        display: block;
      }
    }
  }
}
</style>
