/* source https://stackoverflow.com/a/56204398 
   changed foundObj as array;
*/
function findNestedObj(entireObj, keyToFind, valToFind) {
  const foundObj = [];
  JSON.stringify(entireObj, (_, nestedValue) => {
    if (nestedValue && nestedValue[keyToFind] === valToFind) {
      foundObj.push(nestedValue);
    }
    return nestedValue;
  });
  return foundObj;
}

export default function(showingDesignPage: any, requiredDesignelements: any) {
  const headerDesignElements: any[] = requiredDesignelements.header
    ? requiredDesignelements.header
    : [];
  const contentDesignElements: any[] = requiredDesignelements.content
    ? requiredDesignelements.content
    : [];

  // exit if there are no elements
  if (!headerDesignElements.length && !contentDesignElements.length)
    return false;

  // combine and check elements for existance according to required limit number.
  const checkCounts = [
    ...headerDesignElements,
    ...contentDesignElements
  ].reduce(
    (acc, designElement) => {
      const limit = designElement.limit;
      const name = designElement.name;
      const msg = designElement.msg;

      const foundedDesignElements = findNestedObj(
        showingDesignPage,
        "designId",
        name
      );

      // if limitless
      if (foundedDesignElements.length && limit === -1) acc.found.push(name);
      else if (
        // limit
        foundedDesignElements.length &&
        foundedDesignElements.length === limit
      )
        acc.found.push(msg ? msg : name);
      else acc.notFound.push(msg ? msg : name); // not exist

      return acc;
    },
    { found: [], notFound: [] }
  );

  if (checkCounts.notFound.length) {
    throw {
      err: "DesignElementsNotFound",
      elements: checkCounts
    };
    return;
  } else return checkCounts;
}
